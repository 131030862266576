/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import { Button, Form, message, notification } from "antd";
import API from "../../../api";
import urls from "../../../api/urls";
import nodeURLs from "../../../api/nodeUrls";
const {
  NEW_TYPE_BAG_CREATE,
  GET_ELIGIBLE_DESTINATION_HUB,
  //LIST_OF_BAGS,
  CREATE_BAG,
  FINALISE_BAG,
  DELETE_BAG,
  DELETE_BAG_SHIPMENTS,
  CREATE_EMPTY_BAG,
} = urls;
const { GET_ALL_BAG_LIST } = nodeURLs;
import { capitaliseWithHyphen } from "../../../utils";
import bagWeightLimit from "../../../constants/bagWeightLimit";
import blue_bag from "../../../assests/images/middleMile/blue-bag.svg";
import green_bag from "../../../assests/images/middleMile/green-bag.svg";
import white_bag from "../../../assests/images/middleMile/white-bag.svg";
import orange_bag from "../../../assests/images/middleMile/orange-bag.svg";
import yellow_bag from "../../../assests/images/middleMile/yellow-bag.svg";
import blue_bag_plus from "../../../assests/images/middleMile/blue-bag-plus.svg";
import green_bag_plus from "../../../assests/images/middleMile/green-bag-plus.svg";
import white_bag_plus from "../../../assests/images/middleMile/white-bag-plus.svg";
import yellow_bag_plus from "../../../assests/images/middleMile/yellow-bag-plus.svg";
import "./style.scss";
import { bagInitializeModal } from "./bagInitializeModal";
import { headSection } from "./baggingHeadSection";
import { bodySection } from "./baggingBodySection";

const BaggingAutomate = () => {
  const [currentScanNum, setcurrentScanNum] = useState(null);
  const [showCreateBagModal, setShowCreateBagModal] = useState(false);
  const [contentTypeRequired, setContentTypeRequired] = useState(false);
  const [hubList, setHubList] = useState([]);
  const [originalHubList, setOriginalHubList] = useState([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [bagLoading, setBagLoading] = useState(false);
  const [baggingData, setBaggingData] = useState([]);
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [bagSearchData, setBagSearchData] = useState([]);
  const [selectSection, setSelectSection] = useState(null);
  const [changedBagNum, setChangedBagNum] = useState("");

  const [contentType, setContentType] = useState("NON-DOX");
  const [travelType, setTravelType] = useState("surface");
  const [bagColorr, setBagColor] = useState(null);

  // const userData = JSON.parse(localStorage.getItem("userinfo"));
  // const { userInfo } = JSON.parse(localStorage?.getItem("userId"));
  const bagColor = [
    { label: <img src={blue_bag} width={25} />, value: "#2E7FE1" },
    { label: <img src={green_bag} width={25} />, value: "#70C36E" },
    { label: <img src={yellow_bag} width={25} />, value: "#D2DD3F" },
    { label: <img src={white_bag} width={25} />, value: "#fff" },
    { label: <img src={orange_bag} width={25} />, value: "#FFAB02" },
  ];
  const bagColorobj = {
    "#2E7FE1": <img src={blue_bag} width={50} />,
    "#70C36E": <img src={green_bag} width={50} />,
    "#D2DD3F": <img src={yellow_bag} width={50} />,
    "#fff": <img src={white_bag} width={50} />,
    "#FFAB02": <img src={orange_bag} width={50} />,
  };

  const bagPlusColor = [
    { label: <img src={blue_bag_plus} width={25} />, value: "#2E7FE1" },
    { label: <img src={green_bag_plus} width={25} />, value: "#70C36E" },
    { label: <img src={yellow_bag_plus} width={25} />, value: "#D2DD3F" },
    { label: <img src={white_bag_plus} width={25} />, value: "#fff" },
  ];

  const airWeight = bagWeightLimit?.airWeight;
  const surfaceWeight = bagWeightLimit?.surfaceWeight;

  // useEffect(() => {
  //   if (currentScanNum) {
  //     if (selectSection !== null) {
  //       scanBarCode(false);
  //     } else {
  //       message.error("Please select section for scanning");
  //     }
  //   }
  // }, [currentScanNum]);

  useEffect(() => {
    notification.destroy();
    return () => notification.destroy();
  }, []);

  const scanBarCode = async (showNotification) => {
    setLoading(true);
    let tempBag = JSON.parse(JSON.stringify(baggingData));
    let allFoundIndex = tempBag
      ?.map((_, i) => i)
      ?.filter((e) =>
        tempBag[e]?.eligibleContent?.some(
          (item) => item?.barcodeNo == currentScanNum
        )
      );
    if (allFoundIndex?.length > 0) {
      let parentIndex = 0;
      if (allFoundIndex?.length > 1) {
        if (allFoundIndex?.includes(selectSection)) {
          parentIndex = selectSection;
        } else {
          if (
            tempBag[allFoundIndex[0]]?.bagList?.some(
              (e) => e?.status === "CREATED"
            )
          ) {
            parentIndex = allFoundIndex[0];
          } else {
            parentIndex = allFoundIndex[1];
          }
        }
      } else {
        parentIndex = tempBag.findIndex((e) =>
          e?.eligibleContent.some((item) => item?.barcodeNo == currentScanNum)
        );
      }
      let currentEligibleData = tempBag[parentIndex]?.eligibleContent?.find(
        (e) => e.barcodeNo == currentScanNum
      );

      let bagIndex = tempBag[parentIndex]?.bagList?.findIndex(
        (e) => e.status === "CREATED"
      );
      let weight = tempBag[parentIndex]?.bagList[bagIndex]?.bagShipmentWeight;
      if (bagIndex === -1) {
        setLoading(false);
        message.error(
          `Please create a new bag for
           ${capitaliseWithHyphen(
             `${tempBag[parentIndex]?.destination} - ${tempBag[parentIndex]?.product} - ${tempBag[parentIndex]?.contentType} - ${tempBag[parentIndex]?.mode}`
           )}`
        );
      } else {
        if (
          currentEligibleData?.weight >=
            (tempBag[parentIndex]?.mode === "surface"
              ? surfaceWeight
              : airWeight) &&
          tempBag[parentIndex].bagList[bagIndex].notification === 0
        ) {
          setLoading(false);
          tempBag[parentIndex].bagList[bagIndex].notification =
            tempBag[parentIndex].bagList[bagIndex].notification + 1;
          openNotification(
            "top",
            tempBag,
            parentIndex,
            currentEligibleData,
            bagIndex,
            weight
          );
        } else if (
          weight + currentEligibleData?.weight >=
            (tempBag[parentIndex]?.mode === "surface"
              ? surfaceWeight
              : airWeight) &&
          tempBag[parentIndex].bagList[bagIndex].notification === 0
        ) {
          setLoading(false);
          tempBag[parentIndex].bagList[bagIndex].notification =
            tempBag[parentIndex].bagList[bagIndex].notification + 1;

          openNotification(
            "top",
            tempBag,
            parentIndex,
            currentEligibleData,
            bagIndex,
            weight
          );
        } else if (
          parentIndex !== -1 &&
          bagIndex !== -1
          //  ||
          // showNotification
        ) {
          // let highWeight = currentEligibleData?.weight;
          // tempBag[parentIndex].bagList[bagIndex].bagShipmentWeight =
          //   weight + highWeight;
          // tempBag[parentIndex].bagList[bagIndex].bagContents.push(
          //   currentEligibleData
          // );
          // // const deleteECIndex = tempBag[parentIndex].eligibleContent.findIndex(
          // //   (obj) => obj.barcodeNo === currentScanNum
          // // );
          // // if (deleteECIndex !== -1) {
          // //   tempBag[parentIndex].eligibleContent.splice(deleteECIndex, 1);
          // // }
          // tempBag.forEach((item) => {
          //   item.eligibleContent = item.eligibleContent.filter(
          //     (content) => content.barcodeNo != currentScanNum
          //   );
          // });
          // hitScanApi(tempBag, parentIndex, bagIndex, currentEligibleData);
          // document.getElementById(parentIndex).focus();
          // document.getElementById(parentIndex).scrollIntoView({
          //   behavior: "smooth",
          //   block: "start",
          //   inline: "nearest",
          // });
          afterContinuation(
            tempBag,
            parentIndex,
            currentEligibleData,
            bagIndex,
            weight
          );
        } else {
          setLoading(false);
          hitScanApi(tempBag, null, null, { weight: 0 });
        }
      }
      if (inputSearchValue.length !== 0) {
        const filteredData = filterArray(tempBag, currentScanNum.toString());
        setBagSearchData([...filteredData]);
      }
    } else {
      setLoading(false);
      hitScanApi(tempBag, null, null, { weight: 0 });
    }
  };

  const hitScanApi = async (
    tempBag,
    parentIndex,
    bagIndex,
    currentEligibleData
  ) => {
    let payload = {
      bagContents: [
        {
          barcodeNo: currentScanNum,
          weight: currentEligibleData?.weight,
        },
      ],
    };
    const shipmentPresent = parentIndex != null && bagIndex != null;
    // let bagUrl = shipmentPresent
    //   ? `/${tempBag[parentIndex].bagList[bagIndex].bagBarcodeNo}/content`
    //   : "/content";
    let currentSectionChosen = parentIndex === selectSection;
    if (shipmentPresent) {
      setLoading(false);
      if (!currentSectionChosen) {
        let bagDto = tempBag[parentIndex];
        let placement = "top";
        notification.info({
          message: capitaliseWithHyphen(
            `This shipment is for ${bagDto?.destination} - ${bagDto?.product} - ${bagDto?.contentType} - ${bagDto?.mode} bag`
          ),
          placement,
        });
      }
    } else {
      setLoading(true);
    }
    setBaggingData([...tempBag]);
    if (shipmentPresent) {
      return;
    }
    const bagUrl = `/content?awbNumber=${currentScanNum}`;

    let response = await API.Api(CREATE_BAG + bagUrl).postIdHeader();
    if (response?.status) {
      setLoading(false);
      if (!shipmentPresent) {
        let bagDto = response?.response?.data?.bagDto;
        let destinationName = response?.response?.data?.destinationName;
        let placement = "top";
        notification.info({
          message: capitaliseWithHyphen(
            `This shipment is for ${destinationName} - ${bagDto?.mode} - ${bagDto?.contentType} - ${bagDto?.travelBy} bag`
          ),
          placement,
        });
      }
    } else {
      setLoading(false);
      let placement = "top";
      notification.error({
        message: capitaliseWithHyphen(
          `${response?.response?.data?.message}` || "Bag not created."
        ),
        placement,
        style: {
          width: "auto",
        },
      });
    }
  };

  const afterContinuation = (
    tempBag,
    parentIndex,
    currentEligibleData,
    bagIndex,
    weight
  ) => {
    let highWeight = currentEligibleData?.weight;
    let baglistWithIndex = tempBag[parentIndex].bagList[bagIndex];
    baglistWithIndex.bagShipmentWeight = weight + highWeight;

    baglistWithIndex.bagContents.push(currentEligibleData);
    // const deleteECIndex = tempBag[parentIndex].eligibleContent.findIndex(
    //   (obj) => obj.barcodeNo === currentScanNum
    // );
    // if (deleteECIndex !== -1) {
    //   tempBag[parentIndex].eligibleContent.splice(deleteECIndex, 1);
    // }
    tempBag.forEach((item) => {
      item.eligibleContent = item.eligibleContent.filter(
        (content) => content.barcodeNo != currentScanNum
      );
    });
    hitScanApi(tempBag, parentIndex, bagIndex, currentEligibleData);
    document?.getElementById(parentIndex)?.focus();
    document?.getElementById(parentIndex)?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  const openNotification = (
    placement,
    tempBag,
    parentIndex,
    currentEligibleData,
    bagIndex,
    weight
  ) => {
    notification.warning({
      message: "Weight Overloaded",
      duration: 1000,
      description: (
        <>
          <span style={{ color: "red" }}>
            Bag weight is exceeding. Do you wish to proceed?
          </span>
          <hr className="divider" />
          <div className="flex-end">
            <Button
              className="save-btn"
              onClick={() => {
                // scanBarCode(true);
                afterContinuation(
                  tempBag,
                  parentIndex,
                  currentEligibleData,
                  bagIndex,
                  weight
                );
                notification.destroy();
              }}
            >
              YES
            </Button>
            <Button
              className="cancel-btn ml-1"
              onClick={() => {
                notification.destroy();
              }}
            >
              NO
            </Button>
          </div>
        </>
      ),
      placement,
    });
  };

  useEffect(() => {
    getDestinationHubs();
    getListOfBags();
  }, []);

  //--Barcode Scanner--//
  let barcode = "";
  let interval;
  const handleKeyDown = (event) => {
    if (interval) clearInterval(interval);
    if (event.code == "Enter") {
      if (barcode) handleBarcode(barcode);
      barcode = "";
      return;
    }
    if (event.key != "Shift") barcode += event.key;
    interval = setInterval(() => (barcode = ""), 20);
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleBarcode = (scanned_barcode) => {
    const resultNumber = scanned_barcode?.replace(/[a-zA-Z]/g, "");
    setcurrentScanNum(+resultNumber);
    return +scanned_barcode;
  };

  //--//

  const getDestinationHubs = async () => {
    let { response } = await API.Api(
      GET_ELIGIBLE_DESTINATION_HUB
    ).getWithAllHeaders();
    let temp = [];
    response?.data
      ?.sort((a, b) => a.destinationName.localeCompare(b.destinationName))
      .map((e) => {
        temp.push({
          value: e.destinationHubId
            ? e.destinationHubId
            : `mix_${e.mixDestinationHubId}`,
          label: capitaliseWithHyphen(e.destinationName),
          key: e.destinationHubId
            ? e.destinationHubId
            : `mix_${e.mixDestinationHubId}`,
        });
      });
    setHubList(temp);
    setOriginalHubList(temp);
  };

  const getListOfBags = async () => {
    setLoading(true);
    //let response = await API.Api(LIST_OF_BAGS).getWithAllHeaders();
    const response = await API.Api(GET_ALL_BAG_LIST).NodeGetWithPremise();
    if (response?.status) {
      let data = response?.response?.data;
      setLoading(false);
      let baggingDataTemp = [];
      data.map((item) => {
        baggingDataTemp.push({
          bagColor: item?.bagColour,
          product: item?.product,
          contentType: item?.contentType,
          mode: item?.mode,
          destination: item?.destination,
          destinationId: item?.destinationHubId
            ? item?.destinationHubId
            : `mix_${item?.mixDestinationHubId}`,
          eligibleContent: item?.eligibleShipments,
          bagList: item?.bagDetails?.map((e) => {
            return {
              bagBarcodeNo: e?.bagBarcodeNo,
              status: e?.status,
              bagShipmentWeight: e?.weight || 0,
              bagWeight: e?.weight || 0,
              bagContents: e?.bagContents,
              isDamaged: e?.isDamaged,
              notification: 0,
            };
          }),
        });
      });
      setBaggingData([...baggingDataTemp]);
    } else {
      setLoading(false);
      message.error(response?.data?.message || "Internal server error.");
    }
    // if (status === 200) {
    //   setLoading(false);
    //   let baggingDataTemp = [];
    //   data.map((item) => {
    //     baggingDataTemp.push({
    //       bagColor: item?.bagColour,
    //       product: item?.product,
    //       contentType: item?.contentType,
    //       mode: item?.mode,
    //       destination: item?.destination,
    //       destinationId: item?.destinationHubId
    //         ? item?.destinationHubId
    //         : `mix_${item?.mixDestinationHubId}`,
    //       eligibleContent: item?.eligibleShipments,
    //       bagList: item?.bagDetails?.map((e) => {
    //         // let temp = [];
    //         // e.bagContents.map((et) => {
    //         //   temp.push({
    //         //     id: Math.random(),
    //         //     barcodeNo: et.barcodeNo,
    //         //     weight: et.weight,
    //         //   });
    //         // });
    //         return {
    //           bagBarcodeNo: e?.bagBarcodeNo,
    //           status: e?.status,
    //           bagShipmentWeight: e?.weight,
    //           bagWeight: e?.weight,
    //           bagContents: e?.bagContents,
    //           isDamaged: e?.isDamaged,
    //         };
    //       }),
    //     });
    //   });
    //   setBaggingData([...baggingDataTemp]);
    // }
  };

  const changeMode = (e) => {
    if (e.target.value === "E_COMM") {
      form.setFieldsValue({ bagColour: "#FFAB02" });
      setBagColor("#FFAB02");
    } else {
      setContentTypeRequired(e.target.value == "standard" ? true : false);
      if (e.target.value == "fast track" || e.target.value == "value plus") {
        setTravelType("air");

        form.setFieldsValue({ bagColour: "#70C36E" });
        setBagColor("#70C36E");

        // form.setFieldsValue({ travelBy: "air" });
      } else {
        form.setFieldsValue({ bagColour: null });
        setBagColor(null);

        // let contentType = form.getFieldValue("contentType");
        // let contentType = contentType;
        if (contentType == "DOX") {
          form.setFieldsValue({ bagColour: "#2E7FE1" });
          setBagColor("#2E7FE1");
        } else {
          // let travelBy = form.getFieldValue("travelBy");
          // let travelBy = travelType;
          // form.setFieldsValue({
          //   bagColour:
          //     travelType === "air"
          //       ? "#D2DD3F"
          //       : travelType === "surface"
          //       ? "#fff"
          //       : "",
          // });
          setBagColor(
            travelType === "air"
              ? "#D2DD3F"
              : travelType === "surface"
              ? "#fff"
              : null
          );
        }
      }
    }
  };

  const changeContentType = (e) => {
    if (form.getFieldValue("mode") === "standard") {
      form.setFieldsValue({ bagColour: null });
      setBagColor(null);
    }
    if (contentTypeRequired) {
      if (e === "DOX") {
        setBagColor("#2E7FE1");

        form.setFieldsValue({ bagColour: "#2E7FE1" });
      } else {
        // let travelBy = form.getFieldValue("travelBy");
        let travelBy = travelType;
        form.setFieldsValue({
          bagColour:
            travelBy === "air"
              ? "#D2DD3F"
              : travelBy === "surface"
              ? "#fff"
              : "",
        });
        setBagColor(
          travelBy === "air"
            ? "#D2DD3F"
            : travelBy === "surface"
            ? "#fff"
            : null
        );
      }
    }
  };

  const changeTravelBy = (e) => {
    if (
      // form.getFieldValue("contentType") === "NON-DOX" &&
      contentType === "NON-DOX" &&
      form.getFieldValue("mode") === "standard"
    ) {
      setBagColor(e == "air" ? "#D2DD3F" : e == "surface" ? "#fff" : "");
      form.setFieldsValue({
        bagColour: e == "air" ? "#D2DD3F" : e == "surface" ? "#fff" : "",
      });
    }
  };

  const addNewBag = async (
    index,
    product,
    contentType,
    mode,
    destinationId,
    bagColor
  ) => {
    let destinationHubId = { destinationHubId: destinationId };
    if (JSON.stringify(destinationId).includes("mix")) {
      destinationHubId = {
        mixDestinationHubId: parseInt(destinationId.split("_")[1]),
      };
    }
    setLoading(true);
    // let response = await API.Api(GET_BAG_NUMBER).getWithAllHeaders();
    // if (response?.status) {
    let payload = {
      mode: product,
      contentType: contentType,
      travelBy: mode,
      //bagBarcodeNo: response?.response?.data,
      bagBarcodeNo: changedBagNum,
      ...destinationHubId,
      bagColour: bagColor,
    };
    let response1 = await API.Api(CREATE_EMPTY_BAG).postIdHeader(payload);
    if (response1?.status) {
      setLoading(false);
      let data = response1?.response?.data;
      let baggingDataTemp = baggingData;
      baggingDataTemp[index]?.bagList?.push({
        bagBarcodeNo: data?.bagBarcodeNo,
        status: "CREATED",
        bagShipmentWeight: 0,
        bagWeight: 0,
        bagContents: [],
        isDamaged: null,
      });
      setBaggingData(baggingDataTemp);
      setChangedBagNum("");
    } else {
      setLoading(false);
      message.error(
        response1?.response?.data?.message || "Bag is not created."
      );
    }
  };

  const createBag = async (values) => {
    // setLoading(true);
    setBagLoading(true);
    let payload = {
      ...values,
      bagColour: bagColorr,
      travelBy: travelType,
      contentType: contentType,
    };
    if (JSON.stringify(payload.destinationHubId).includes("mix")) {
      payload["mixDestinationHubId"] = parseInt(
        payload.destinationHubId.split("_")[1]
      );
      delete payload.destinationHubId;
    }
    let response = await API.Api(NEW_TYPE_BAG_CREATE).postIdHeader(payload);
    if (response.status) {
      setBagLoading(false);
      getListOfBags();

      setShowCreateBagModal(false);
      setTravelType("surface");
      setContentType("NON-DOX");
      setBagColor(null);
      form.resetFields();
    } else {
      message.error(response?.response?.data?.message);
      setBagLoading(false);
    }
  };

  ///---Bag Finalization Functions---///

  const finaliseBagContents = (content, barCode, bagWeight, weightLimit) => {
    if (bagWeight < weightLimit) {
      openNotificationWeightUtilize(
        content,
        barCode,
        bagWeight,
        "Bag capacity is not utilized. Do you want to proceed?"
      );
    } else if (bagWeight > weightLimit) {
      openNotificationWeightUtilize(
        content,
        barCode,
        bagWeight,
        "Bag weight is exceeding. Do you wish to proceed?"
      );
    } else {
      finaliseApiHit(content, barCode, bagWeight);
    }
  };

  const openNotificationWeightUtilize = (content, barCode, bagWeight, text) => {
    notification.warning({
      message: "Weight Unutilized",
      duration: 1000,
      description: (
        <>
          <span style={{ color: "red" }}>{text}</span>
          <hr className="divider" />
          <div className="flex-end">
            <Button
              className="save-btn"
              onClick={async () => {
                await finaliseApiHit(content, barCode, bagWeight);
                notification.destroy();
              }}
            >
              YES
            </Button>
            <Button
              className="cancel-btn ml-1"
              onClick={() => notification.destroy()}
            >
              NO
            </Button>
          </div>
        </>
      ),
      placement: "top",
    });
  };

  const finaliseApiHit = async (content, barCode, bagWeight) => {
    setLoading(true);
    let payload = {
      barcodes: {
        awbNumber: content?.map((item) => item.barcodeNo),
      },
    };
    // const response = await API.Api(
    //   `${CREATE_BAG}/${barCode}/status/FINALISED?bagWeight=${bagWeight}`
    // ).putIdHeader();
    const response = await API.Api(`${FINALISE_BAG}${barCode}`).putIdHeader(
      payload
    );

    if (response.status) {
      setLoading(false);
      let baggingDataTemp = baggingData;
      let parentIndex = baggingDataTemp.findIndex((e) =>
        e.bagList.find((item) => item.bagBarcodeNo == barCode)
      );
      let currentBagIndex = baggingDataTemp[parentIndex].bagList.findIndex(
        (e) => e.bagBarcodeNo == barCode
      );
      baggingDataTemp[parentIndex].bagList[currentBagIndex].status =
        "FINALISED";
      setBaggingData([...baggingDataTemp]);
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Failed.");
    }
  };

  ///---Bag Finalization Functions End---///

  const filterArray = (data, currValue) => {
    let dataNew = [];
    data.map((e) => {
      dataNew.push({
        ...e,
        awbList: e.eligibleContent
          .map((awb) => awb.barcodeNo)
          .concat(
            e.bagList
              .map((item) => item.bagContents.map((data) => data.barcodeNo))
              .flat()
          ),
      });
    });
    return dataNew?.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(currValue.toLowerCase())
      )
    );
  };

  const searchBag = (e) => {
    const currValue = e.target.value;
    setInputSearchValue(currValue);
    const filteredData =
      inputSearchValue.length > 0 || inputSearchValue.length == 0
        ? filterArray(baggingData, currValue)
        : filterArray(baggingData, currValue);
    setBagSearchData(filteredData);
  };

  let scanningData = baggingData
    ?.map((e) =>
      e?.bagList?.reduce(function (acc, obj) {
        return acc + obj?.bagContents?.length;
      }, 0)
    )
    .reduce(function (acc, obj) {
      return acc + obj;
    }, 0);
  let totaldata = baggingData?.reduce(function (acc, obj) {
    return acc + obj?.eligibleContent?.length;
  }, 0);

  ///---Discarding Bag---///
  const discardBag = async (bagNo, bagContentDeleteList, parentIndex) => {
    setLoading(true);
    let response1 = await API.Api(DELETE_BAG + `${bagNo}`).deleteIdHeader();
    if (response1?.status) {
      message.success(response1?.response?.data);
      if (selectSection == parentIndex) {
        if (baggingData[parentIndex]?.bagList?.length == 1) {
          setSelectSection(null);
        }
      }
      const response = await API.Api(GET_ALL_BAG_LIST).NodeGetWithPremise();

      let baggingDataTemp = baggingData;
      baggingDataTemp.forEach((item) => {
        item.bagList = item.bagList.filter((bag) => bag.bagBarcodeNo != bagNo);
      });
      baggingDataTemp = baggingDataTemp.filter(
        (item) => item.bagList.length > 0
      );
      if (response?.status) {
        setLoading(false);
        const data = response?.response?.data || [];
        setBagDataAfterDelete(baggingDataTemp, data);
      } else {
        message.error(response?.data?.message || "Internal server error.");
      }
    } else {
      message.error(response1?.response?.data?.message);
    }
  };

  const discardSingleShipment = async (
    barcodeNo,
    barcodeList,
    deletedContent,
    eligibleList,
    parentIndex
  ) => {
    //console.log(deletedContent, barcodeList);

    const response = await API.Api(GET_ALL_BAG_LIST).NodeGetWithPremise();

    let baggingDataTemp = baggingData;
    baggingDataTemp.forEach((item) => {
      item.bagList.forEach((bag) => {
        // Filter out the target object if it exists in bagContents
        bag.bagContents = bag.bagContents.filter(
          (content) => content.barcodeNo != deletedContent.barcodeNo
        );
      });
    });

    if (response?.status) {
      setLoading(false);
      const data = response?.response?.data || [];
      setBagDataAfterDelete(baggingDataTemp, data);
    } else {
      message.error(response?.data?.message || "Internal server error.");
    }
  };

  const setBagDataAfterDelete = (baggingDataTemp, data) => {
    baggingDataTemp = data.map((item, apiIndex) => {
      const barcodesToRemove = new Set();
      let arr2 = item?.eligibleShipments;

      baggingData.forEach((item) => {
        item.bagList.forEach((bag) => {
          bag.bagContents.forEach((content) => {
            barcodesToRemove.add(content.barcodeNo);
          });
        });
      });

      // Filter arr2 by removing any items whose barcodeNo is in barcodesToRemove
      arr2 = arr2.filter((item) => !barcodesToRemove.has(item.barcodeNo));
      return {
        bagColor: item?.bagColour,
        product: item?.product,
        contentType: item?.contentType,
        mode: item?.mode,
        destination: item?.destination,
        destinationId:
          item?.destinationHubId || `mix_${item?.mixDestinationHubId}`,
        //eligibleContent: item?.eligibleShipments,
        eligibleContent: arr2,
        bagList: item?.bagDetails?.map((e, bagInnerIndex) => {
          return {
            bagBarcodeNo: e?.bagBarcodeNo,
            status: e?.status,
            bagShipmentWeight: e?.weight || 0,
            bagWeight: e?.weight || 0,
            bagContents:
              e?.status === "FINALISED"
                ? e?.bagContents
                : baggingDataTemp[apiIndex]?.bagList[bagInnerIndex]
                    ?.bagContents,
            isDamaged: e?.isDamaged,
          };
        }),
      };
    });
    if (inputSearchValue.length !== 0) {
      const filteredData = filterArray(
        baggingDataTemp,
        inputSearchValue.toString()
      );
      setBagSearchData([...filteredData]);
    }
    setBaggingData(baggingDataTemp);
  };

  ///---///

  const filterDestinationList = (e) => {
    let data = originalHubList;
    let filteredArray = data.filter((item) =>
      e.some((check) => typeof check === typeof item.value)
    );
    if (e.length > 0) {
      setHubList([...filteredArray]);
    } else {
      setHubList(data);
    }
  };
  return (
    <>
      <div className="master-main-container">
        {headSection(
          currentScanNum,
          setcurrentScanNum,
          setSelectSection,
          selectSection,
          baggingData,
          searchBag,
          setShowCreateBagModal,
          //getBagBarCodeNum,
          totaldata,
          scanningData,
          scanBarCode
        )}
        <hr className="divider" style={{ marginTop: "-0.2rem" }} />
        {bodySection(
          loading,
          baggingData,
          inputSearchValue,
          bagSearchData,
          bagColor,
          setChangedBagNum,
          changedBagNum,
          addNewBag,
          bagPlusColor,
          discardBag,
          discardSingleShipment,
          surfaceWeight,
          airWeight,
          finaliseBagContents
        )}
      </div>
      {bagInitializeModal(
        showCreateBagModal,
        form,
        setShowCreateBagModal,
        setContentTypeRequired,
        createBag,
        changeMode,
        changeContentType,
        changeTravelBy,
        hubList,
        bagColorobj,
        bagLoading,
        filterDestinationList,
        contentType,
        setContentType,
        travelType,
        setTravelType,
        bagColorr,
        setBagColor
      )}
    </>
  );
};
export default BaggingAutomate;
