/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import { Table, Spin, message, Button } from "antd";
import { serialNum } from "../../../utils";
import bag_icon from "../../../assests/images/master/bag_icon.png";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
import blue_bag from "../../../assests/images/middleMile/blue-bag.svg";
import green_bag from "../../../assests/images/middleMile/green-bag.svg";
import white_bag from "../../../assests/images/middleMile/white-bag.svg";
import orange_bag from "../../../assests/images/middleMile/orange-bag.svg";
import yellow_bag from "../../../assests/images/middleMile/yellow-bag.svg";
import cross_img from "../../../assests/images/trash-can.png";

import urls from "../../../api/urls";
import API from "../../../api";

import "./style.scss";
import { render } from "@testing-library/react";
const OutScanBag = () => {
  const { GET_ALL_BAGS, DELETE_BAG } = urls;
  const [allBagaes, setAllBagages] = useState();
  const [loading, setLoading] = useState(false);
  const [totalPageSize, setTotalPageSize] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const colorDictionary = {
    "#fff": <img src={white_bag} width={25} />,
    "#2E7FE1": <img src={blue_bag} width={25} />,
    "#70C36E": <img src={green_bag} width={25} />,
    "#D2DD3F": <img src={yellow_bag} width={25} />,
    "#FFAB02": <img src={orange_bag} width={25} />,
  };

  const [pageSize, setPageSize] = useState(10);
  const columns = [
    {
      title: "Sl No",
      align: "center",
      with: 10,
      render: (a, b, idx) => serialNum(10, currentPage, idx),
    },
    {
      title: "Bag ID / AWB No",
      dataIndex: "bagBarcodeNo",
      key: "bagBarcodeNo",
      render: (text, record) => {
        return record?.bagColour === null ? (
          <span>{text}</span>
        ) : (
          <div className="align-center">
            <span>{text}</span>
            {record?.status === "FINALISED" ? (
              <img
                src={bag_icon}
                style={{ width: "10px", marginLeft: "5px" }}
              ></img>
            ) : null}
          </div>
        );
      },
    },
    {
      title: "Destination",
      dataIndex: "destinationName",
      key: "destinationName",
      render: (text) => (
        <div>
          {text
            .toLowerCase()
            .split(" ")
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(" ")}
        </div>
      ),
    },
    {
      title: "Bag Colour",
      dataIndex: "bagColour",
      key: "bagColour",
      render: (text, _record) => {
        return <div> {colorDictionary[text] || "------"}</div>;
      },
    },
    {
      title: "Bags/Individual Shipments Weight(Kg)",
      dataIndex: "weight",
      key: "weight",
      render: (text) => <div>{text || "------"}</div>,
    },
    {
      title: "No of Shipments",
      dataIndex: "noOfShipments",
      key: "noOfShipments",
      render: (text) => <div>{text || "------"}</div>,
    },
    {
      title: "Date & Time",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (text) => <div>{text?.replaceAll(" ", "/")}</div>,
    },
    {
      title: "Action",
      dataIndex: "delete",
      key: "delete",
      render: (text, rec) => (
        <Button
          disabled={!rec?.bagColour}
          type="text"
          onClick={() => discardBag(rec?.bagBarcodeNo)}
        >
          <img src={cross_img} className="delete-img" />
        </Button>
      ),
    },
  ];

  useEffect(() => {
    getAllBags("?pageNum=0&pageSize=10");
  }, []);

  const discardBag = async (bagNo) => {
    setLoading(true);
    let response1 = await API.Api(DELETE_BAG + `${bagNo}`).deleteIdHeader();

    if (response1?.status) {
      getAllBags(`?pageNum=${currentPage - 1}&pageSize=${pageSize}`);
      message.success(response1?.response?.data);
      setLoading(false);
    } else {
      message.error(response1?.data?.message || "Internal server error.");
    }
  };

  const getAllBags = async (pagination) => {
    setLoading(true);
    let response = await API.Api(
      GET_ALL_BAGS + pagination
    ).getWithUserNPremiseHeader();
    if (response?.response?.status == 200) {
      // let filteredData = response?.response?.data?.filter(
      //   (data) =>
      //     data?.status?.toUpperCase() == "FINALISED" ||
      //     data?.status?.toUpperCase() == "SORTED" ||
      //     data?.status?.toUpperCase() == "INSCANNED"
      // );
      setAllBagages(response?.response?.data?.data);
      setTotalPageSize(response?.response?.data?.count);

      setLoading(false);
    } else {
      setLoading(false);
      message.error(
        response?.response?.data?.message || "Something went wrong"
      );
    }
  };
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };
  const paginationHandle = (pagination) => {
    setPageSize(pagination.pageSize);
    setCurrentPage(pagination.current);
    getAllBags(
      `?pageNum=${pagination.current - 1}&pageSize=${pagination.pageSize}`
    );
  };
  return (
    <Spin
      spinning={loading}
      size="large"
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      <div className=" bagging-shipment">
        <div className="lcr-table-container">
          <Table
            className="table-head-sticky"
            columns={columns}
            dataSource={allBagaes}
            pagination={{
              pageSize: pageSize,
              total: totalPageSize,
              current: currentPage,
              itemRender: itemRender,
            }}
            onChange={paginationHandle}
          />
        </div>
      </div>
    </Spin>
  );
};

export default OutScanBag;
