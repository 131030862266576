/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  List,
  Form,
  Spin,
  message,
  Carousel,
  Tooltip,
  Radio,
  Text,
  Tabs,
} from "antd";

import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import { shallowEqual, useSelector, useDispatch } from "react-redux";

import "./index.scss";
import dashboardAnnouncementIcone from "../../assests/images/dashboard-annonsment.svg";
import dashbordMessageIcon from "../../assests/images/dashbord-message-icon.svg";
import accountBookingArrowIcon from "../../assests/images/arrow.svg";
import calenderIcon from "../../assests/images/calenderIcon.svg";

import { dashboardMenuList } from "../../config/dashboard";
import DashbordCard from "./DashbordCard";
import constants from "../../constants/messages";
import { getAccountService } from "../../actionsMethods/cashBookingMethods";
import { APIs, getToken, getPremiseId } from "../../utils/worker";
import axios from "axios";
import API from "../../api";
import { ReloadOutlined } from "@ant-design/icons";
import moment from "moment";
import nodeURLs from "../../api/nodeUrls";
import { capitalize } from "../../utils";
import urls from "../../api/urls";

const token = getToken();
const premiseId = getPremiseId();

const { SHIPMENT_COUNT } = nodeURLs;

let userinfo = JSON.parse(localStorage.getItem("userinfo"));

const getRole = userinfo?.roles[0]?.replace(/.*_/, "");

const { TabPane } = Tabs;
function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ANNOUNCEMENT } = constants;
  const { DASHBORD_SCANNED_UNSCANNED } = urls;

  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);

  const [accountDetails, setAccountDetails] = useState();
  const [day, setDay] = useState(1);
  const [onClickTime, setOnClickTime] = useState("");
  const [loading, setLoading] = useState(false);
  const [todayData, setTodayData] = useState([]);
  const [activeTab, setActiveTab] = useState("inbound");

  const getpremiseResponse = useSelector(
    (state) => state?.appReducer?.getCpPremiseReducer,
    shallowEqual
  );

  const { fetching, result } = getpremiseResponse;

  useEffect(() => {
    if (!fetching) {
      localStorage.setItem(
        "permises",
        JSON.stringify(result, getpremiseResponse?.result?.premises?.stateCode)
      );
    }
  }, [getpremiseResponse]);

  function getDate(day = "today") {
    const date = new Date();
    setDay(`${moment().format("hh:mm a")}  ${day}`);
    if (day === "yesterday") {
      setDay(day);
      // setOnClickTime(day);
      date.setDate(date.getDate() - 1);
    }

    return date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  }

  useEffect(() => {
    navigate("/dashboard", { state: { test: "test" } });

    getTodaysData("today");
    setAccountDetails(JSON.parse(localStorage.getItem("userinfo")));
    getAnnouncements();
  }, []);

  useEffect(() => {
    if (accountDetails !== undefined) {
      dispatch(getAccountService(accountDetails?.username));
    }
  }, [accountDetails]);

  const getTodaysData = async (day) => {
    setLoading(true);
    let dates = getDate(day);

    const response = await API.Api(
      `${DASHBORD_SCANNED_UNSCANNED}`
    ).getWithPremiseHeader();

    if (response?.status) {
      let parentData = response.response.data;

      getScannedAndUnscanned(dates, parentData);
    } else {
      setLoading(false);
      setTodayData([]);

      message.error(response?.statusText || "Internal server error");
    }

    setLoading(false);
  };
  const getScannedAndUnscanned = async (date = null, parentData) => {
    setLoading(true);

    const response = await API.Api(SHIPMENT_COUNT + date).NodeGetWithPremise();

    if (response?.status) {
      let data = response.response.data;
      let obj = {
        inbound: {
          ...parentData?.inBound,
          ...data?.inbound,
        },
        outbound: {
          ...parentData?.outBound,
          ...data?.outbound,
        },
      };
      setTodayData(obj);
    } else {
      setLoading(false);
      setTodayData([]);

      message.error(response?.statusText || "Internal server error");
    }

    setLoading(false);
  };

  const getAnnouncements = async () => {
    try {
      let response = await axios.get(
        `${APIs.baseURL}/broadcast-service/v1/broadcasts/show`,
        {
          headers: {
            Authorization: token,
            "SMCS-PREMISE-ID": premiseId?.split("Bearer ")[1],
          },
        }
      );
      if (response?.status == 200) {
        setDataSource(response?.data);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          color: "black",
          fontSize: "15px",
          lineHeight: "1.5715 ",
        }}
        onClick={onClick}
      >
        <RightOutlined />
      </div>
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          color: "black",
          fontSize: "15px",
          lineHeight: "1.5715 ",
        }}
        onClick={onClick}
      >
        <LeftOutlined />
      </div>
    );
  };

  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const radioOnChange = (event) => {
    console.log(event);
  };

  return (
    <div className="dashboard-container">
      <Spin spinning={fetching}>
        <Form
          form={form}
          layout="vertical"
          // onFinish={onFinish}
          autoComplete="off"
        >
          <div className="dashboardHeading">Dashboard</div>

          <Row gutter={[32, 32]} className="dashboard-container-row">
            <Col
              className="dashboard-container-col"
              xl={16}
              lg={16}
              md={24}
              sm={24}
              xs={24}
            >
              {getRole === "HUBADMIN" && (
                <Spin spinning={todayData === null}>
                  <Row>
                    <Col
                      xl={24}
                      lg={24}
                      md={24}
                      sm={24}
                      xs={24}
                      className="dashboard-container-col-innercol"
                    >
                      <Row>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                          <CustomTabs
                            className="pl-1"
                            activeTab={activeTab}
                            tabChange={(e) => setActiveTab(e)}
                          />
                        </Col>
                        <Col
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className="flex-end p-1"
                        >
                          <Radio.Group
                            buttonStyle="solid"
                            // style={{ width: "20%" }}
                            defaultValue="today"
                            onChange={(e) => getTodaysData(e.target.value)}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <Radio.Button
                                value="yesterday"
                                style={{ flex: 1, textAlign: "center" }}
                              >
                                Yesterday
                              </Radio.Button>
                              <Radio.Button
                                value="today"
                                style={{ flex: 1, textAlign: "center" }}
                              >
                                Today
                              </Radio.Button>
                            </div>
                          </Radio.Group>
                        </Col>
                      </Row>
                      <div className="dashboard-container-col-innercol-trackOrder">
                        <span
                          style={{ color: "green", fontSize: "13px" }}
                          className="dashboard-container-col-innercol-trackOrder-name"
                        >
                          {/* Shipment Status */}
                        </span>
                        <div style={{ color: "red", fontSize: "13px" }}>
                          Results as on&nbsp;{day}
                          {day !== "yesterday" ? (
                            <Tooltip title={"Reload"}>
                              <ReloadOutlined
                                // onClick={getCurrentTime}
                                onClick={() => getTodaysData("today")}
                                style={{
                                  fontSize: 18,
                                  paddingLeft: "10px",
                                  color: "black",
                                }}
                              />
                            </Tooltip>
                          ) : null}
                        </div>
                      </div>

                      <div className="custom-carousel">
                        <StatusRow
                          title="Shipment Status"
                          loading={loading}
                          status={{
                            expected: todayData[activeTab]?.expected?.shipment,
                            arrived: todayData[activeTab]?.arrived?.shipment,
                            inscanned:
                              todayData[activeTab]?.inscanned?.shipment,
                            outscanned:
                              todayData[activeTab]?.outscanned?.shipment,
                            pendingInscan:
                              todayData[activeTab]?.pendingInscan?.shipment,
                          }}
                        />
                        {activeTab == "inbound" && (
                          <StatusRow
                            loading={loading}
                            title="Bag Status"
                            status={{
                              expected: todayData[activeTab]?.expected?.bag,
                              arrived: todayData[activeTab]?.arrived?.bag,
                              inscanned: todayData[activeTab]?.inscanned?.bag,
                              outscanned: todayData[activeTab]?.outscanned?.bag,
                              pendingInscan:
                                todayData[activeTab]?.pendingInscan?.bag,
                            }}
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                </Spin>
              )}
              <Row gutter={[32, 32]}>
                {dashboardMenuList.map((item, index) => {
                  if (
                    item?.role === "" ||
                    item.role.some((e) => accountDetails?.roles?.includes(e))
                  ) {
                    return (
                      <Col
                        xl={6}
                        lg={8}
                        md={10}
                        sm={12}
                        xs={12}
                        className="cash-booking"
                        key={index}
                      >
                        <DashbordCard
                          accountBookingArrowIcon={accountBookingArrowIcon}
                          accountBookingIcon={item.icon}
                          watchIcon={calenderIcon}
                          bookingType={item.title}
                          route={item.route}
                          id="cashBooking"
                        />
                      </Col>
                    );
                  }
                })}
              </Row>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Card
                    size="small"
                    className="dashboard-container-card-div"
                    style={{ height: "380px", marginBottom: "20px" }}
                    title={
                      <div>
                        {" "}
                        <img
                          width="24px"
                          height="24px"
                          className="dashboard-container-card-div-icon"
                          src={dashboardAnnouncementIcone}
                        />
                        <span className="announcement-text">
                          {" "}
                          {ANNOUNCEMENT}{" "}
                        </span>
                      </div>
                    }
                  >
                    <List
                      itemLayout="horizontal"
                      dataSource={dataSource}
                      pagination={
                        dataSource.length > 3
                          ? {
                              pageSize: 3,
                            }
                          : false
                      }
                      renderItem={(item) => (
                        <List.Item>
                          <List.Item.Meta
                            title={
                              <div>
                                {" "}
                                <img
                                  width="29px"
                                  height="29px"
                                  className="dashboard-container-card-div-messageIcon"
                                  src={dashbordMessageIcon}
                                />
                                <span
                                  className="listTitle"
                                  style={{
                                    textTransform: "capitalize",
                                    color:
                                      item.priorityType === "HIGH"
                                        ? "#C40000"
                                        : item.priorityType === "MEDIUM"
                                        ? "#DF8100"
                                        : "#179503",
                                  }}
                                >
                                  {item.title}
                                </span>
                              </div>
                            }
                            description={
                              <div>
                                <span className="listDiscription">
                                  {item.validFrom + " - " + item.validTo}
                                </span>
                              </div>
                            }
                          />
                        </List.Item>
                      )}
                    />
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Spin>
    </div>
  );
}
Dashboard.propTypes = {
  data1: PropTypes.number,
};

export default Dashboard;

const CustomTabs = ({ activeTab, tabChange }) => {
  const tabStyles = (tab) => ({
    flex: 1,
    textAlign: "center",
    paddingLeft: "50px",
    color: activeTab === tab ? "#000" : "#a6a6a6",
    fontWeight: activeTab === tab ? "bold" : "normal",
    fontSize: "16px",
    cursor: "pointer",
    paddingBottom: "10px",
  });

  const indicatorStyles = (isPrimary) => ({
    position: "absolute",
    width: "50%",
    // height: "3px",
    height: "6px",
    borderBottomLeftRadius:
      activeTab === (isPrimary ? "outbound" : "inbound") ? "5px" : "0",
    borderBottomRightRadius:
      activeTab === (isPrimary ? "outbound" : "inbound") ? "0" : "5px",
    backgroundColor: isPrimary ? "#1890ff" : "#ABAFB1",
    bottom: 0,
    left: activeTab === (isPrimary ? "outbound" : "inbound") ? "10%" : "60%",
    transition: "left 0.6s ease-in-out, border-radius 0.6s ease-in-out",
  });

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        paddingLeft: "10%",
        paddingTop: "30px",
        position: "relative",
      }}
    >
      <div onClick={() => tabChange("outbound")} style={tabStyles("outbound")}>
        CP
      </div>
      <div onClick={() => tabChange("inbound")} style={tabStyles("inbound")}>
        Hub
      </div>
      <div style={indicatorStyles(true)} />
      <div style={indicatorStyles(false)} />
    </div>
  );
};

const ShipmentStatusCompo = ({ array, styleObj }) => (
  <Row gutter={12} className="">
    {array?.map((item, i) => (
      <Col lg={6} md={6} sm={6} key={i}>
        <div
          className="shipmentStatusContainer"
          style={styleObj[item?.status]?.styles}
        >
          <div className="textNumber" style={styleObj[item?.status]?.textColor}>
            {item?.total_count}
          </div>
          <div>{capitalize(item?.status)}</div>
        </div>
      </Col>
    ))}
  </Row>
);

const StatusRow = ({ title, status, loading }) => (
  <Spin spinning={loading}>
    <div className="pl-1" style={{ color: "#008000" }}>
      {title}
    </div>
    <div className="autoAdjust p-1">
      <div>
        <StatusCard
          count={status.expected}
          label="Expected"
          style={cardStyles.expected}
        />
      </div>
      <div>
        <StatusCard
          count={status.arrived}
          label="Pending&nbsp;Inscan"
          style={cardStyles.arrived}
        />
      </div>
      <div>
        <StatusCard
          count={status.inscanned}
          label="Inscan"
          style={cardStyles.inscanned}
        />
      </div>
      <div>
        <StatusCard
          count={status.outscanned}
          label="Pending&nbsp;Outscan"
          style={cardStyles.outscanned}
        />
      </div>
      <div>
        <StatusCard
          count={status.pendingInscan}
          label="Outscan"
          style={cardStyles.pendingInscan}
        />
      </div>
    </div>
  </Spin>
);

const cardStyles = {
  expected: {
    backgroundColor: "#FFFBE6",
    borderColor: "#FFE58F",
    borderRadius: "10px",
  },
  arrived: {
    backgroundColor: "#F6FFED",
    borderColor: "#B7EB8F",
    borderRadius: "10px",
  },
  inscanned: {
    backgroundColor: "#FFF1F0",
    borderColor: "#FFA39E",
    borderRadius: "10px",
  },
  outscanned: {
    backgroundColor: "#F6FFED",
    borderColor: "#B7EB8F",
    borderRadius: "10px",
  },
  pendingInscan: {
    backgroundColor: "#FFFBE6",
    borderColor: "#FFE58F",
    borderRadius: "10px",
  },
  pendingOutscan: {
    backgroundColor: "#FFFBE6",
    borderColor: "#FFE58F",
    borderRadius: "10px",
  },
};

const StatusCard = ({ count, label, style }) => (
  <Card bordered style={{ textAlign: "center", ...style }}>
    <div>{count ? count : 0}</div>
    <div>{label}</div>
  </Card>
);
